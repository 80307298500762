import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Survivant } from '../model/survivant';
import { SurvieService } from '../services/survie.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['../nav/nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignComponent implements OnInit {
  survivants : Survivant[];
  constructor(private survieServices : SurvieService) { }

  ngOnInit(): void {
    this.survivants = this.survieServices.survivants;
    setTimeout(() => { 
      for(let i = 0; i<this.survivants.length; i++){
        let pseudo = this.survivants[i].pseudo;
        let url = this.survivants[i].url;
        this.survieServices.recupSign(pseudo, url);
      }
    }, 1000); 
  }
}
