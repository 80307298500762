<div>
    <div class="bonusMalus"><div class="fondcode"><div class="titrepresa">BONUS / MALUS</div>
    <div style="display:none">
      <div id="page1" ></div>
      <div id="page2" ></div>
      <div id="page3" ></div>
      <br /><br />
    </div>

    <div id="codeAGenerer"><div id="recapBonusMalus"></div></div>
  </div>
  <a href="#" id="generer-html">&#9658; Générer le code</a><br />
  <textarea id="txt-genenere" readonly>
  </textarea>
</div>
