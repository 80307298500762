import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Survivant } from '../model/survivant';
import { SurvieService } from '../services/survie.service';

@Component({
  selector: 'app-survie',
  templateUrl: './survie.component.html',
  styleUrls: ['../nav/nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurvieComponent implements OnInit {
  survivants : Survivant[];
  constructor(private survieServices : SurvieService) { }

  ngOnInit(): void {
    setTimeout(() => { 
        this.survieServices.recupListe();
    }, 500); 
  }
 

}
