import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Post } from '../model/post.model';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  private posts : Post[] = [
    {
      titre : "test Titre",
      sousTitres : ['test1', 'test2']
}];
  postSubject = new Subject<Post[]>();
  blocSousTitre = '';
  blocText = '';
  blocImage = '';
  blocSousTitreForm = '';
  blocTextForm = '';
  blocImageForm = '';
  sousTitre='';

  constructor() { }
  ajouterSousTitre(){
    this.blocSousTitreForm = '<label>sous titre à ajouter :</label><input type="text" [(ngModel)]="SousTitre"><button (Click)="onAjouterContenuSousTitre()">Ajouter un sous-titre</button>';
    this.blocSousTitre = '<div class="soustitresuj"></div>';
    $('.formulaire').append(this.blocSousTitreForm);
    $('.fondcode').append(this.blocSousTitre);
}
ajouterContenuSousTitre(/*sousTitre : string*/){
  console.log("test");
  this.sousTitre = "test";
  $('.soustitresuj').append(this.sousTitre);
}
  emitPosts(){
    this.postSubject.next(this.posts.slice());
  }

  addPost ( post : Post){
    this.posts.push(post);
    this.emitPosts();
  }

}
