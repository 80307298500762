import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Post } from '../model/post.model';
import { PostService } from '../services/post.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['../nav/nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostComponent implements OnInit {
  postForm : FormGroup;
  posts: Post[];
  postSubscription: Subscription;
  @ViewChild("div") div: ElementRef;
  @ViewChild("div2") div2: ElementRef;

  i : number = 0;
  idPresent : string[] = [];

  constructor(private postService : PostService,
              private renderer: Renderer2) { }

  ngOnInit() {
  }

  addTitre(id:string) {
    this.i+=1;
    const input: HTMLInputElement = this.renderer.createElement("input");
    input.id = id+this.i;
    this.renderer.appendChild(this.div.nativeElement, input);
    const button: HTMLButtonElement= this.renderer.createElement("button");
    button.innerHTML = "ajouter";
    button.id="B1"+input.id;
    button.addEventListener('click', this.addTitrecontent.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button);
    const button2: HTMLButtonElement= this.renderer.createElement("button");
    button2.innerHTML = "supprimer cet élément";
    button2.id="B2"+input.id;
    button2.addEventListener('click', this.deleteTitre.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button2);
  }
  addTitrecontent(id:string){
    var titre = (<HTMLInputElement>document.getElementById(id)).value;
    if(this.idPresent.indexOf("R"+id)>-1){
      document.getElementById('R'+id).innerHTML = titre;
    }else{
      const div: HTMLDivElement = this.renderer.createElement("div");
      div.innerHTML = titre;
      div.className = "titrepresa";
      div.id = "R"+id;
      this.renderer.appendChild(this.div2.nativeElement, div);
      this.idPresent.push("R"+id);
    }
  }
  deleteTitre(id:string){
    var titre = document.getElementById(id);
    var titre2 = document.getElementById('R'+id); 
    var button = document.getElementById('B1'+id);
    var button2 = document.getElementById('B2'+id);
    this.renderer.removeChild(this.div2.nativeElement,titre2);
    this.renderer.removeChild(this.div.nativeElement,titre);
    this.renderer.removeChild(this.div.nativeElement,button);
    this.renderer.removeChild(this.div.nativeElement,button2);
  }
  addSousTitre(id:string) {
    this.i+=1;
    const input: HTMLInputElement = this.renderer.createElement("input");
    input.id = id+this.i;
    this.idPresent.push(input.id);
    this.renderer.appendChild(this.div.nativeElement, input);
    const button: HTMLButtonElement= this.renderer.createElement("button");
    button.innerHTML = "ajouter";
    button.id="B1"+input.id;
    button.addEventListener('click', this.addSousTitrecontent.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button);
    const button2: HTMLButtonElement= this.renderer.createElement("button");
    button2.innerHTML = "supprimer cet élément";
    button2.id="B2"+input.id;
    button2.addEventListener('click', this.deleteSousTitre.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button2);
  }
  addSousTitrecontent(id:string){
    var sousTitre = (<HTMLInputElement>document.getElementById(id)).value;
    if(this.idPresent.indexOf("R"+id)>-1){
      document.getElementById("R"+id).innerHTML = sousTitre;
    }else{
      const div: HTMLDivElement = this.renderer.createElement("div");
      div.innerHTML = sousTitre;
      div.className = "soustitresuj";
      div.id = "R"+id;
      this.renderer.appendChild(this.div2.nativeElement, div);
      this.idPresent.push("R"+id);
    }
  }
  deleteSousTitre(id:string){
    var titre = document.getElementById(id);
    var titre2 = document.getElementById('R'+id); 
    var button = document.getElementById('B1'+id);
    var button2 = document.getElementById('B2'+id);
    this.renderer.removeChild(this.div2.nativeElement,titre2);
    this.renderer.removeChild(this.div.nativeElement,titre);
    this.renderer.removeChild(this.div.nativeElement,button);
    this.renderer.removeChild(this.div.nativeElement,button2);
  }

  addblocTexte(id:string) {
    this.i+=1;
    const textarea: HTMLTextAreaElement = this.renderer.createElement("textarea");
    textarea.id = id+this.i;
    this.renderer.appendChild(this.div.nativeElement, textarea);
    const button: HTMLButtonElement= this.renderer.createElement("button");
    button.innerHTML = "ajouter";
    button.id="B1"+textarea.id;
    button.addEventListener('click', this.addblocTexteContent.bind(this, textarea.id));
    this.renderer.appendChild(this.div.nativeElement, button);
    const button2: HTMLButtonElement= this.renderer.createElement("button");
    button2.innerHTML = "supprimer cet élément";
    button2.id="B2"+textarea.id;
    button2.addEventListener('click', this.deleteBlocTexte.bind(this, textarea.id));
    this.renderer.appendChild(this.div.nativeElement, button2);
  }
  addblocTexteContent(id:string){
    var blocTexte = (<HTMLInputElement>document.getElementById(id)).value;
    if(this.idPresent.indexOf("R"+id)>-1){
      document.getElementById('R'+id).innerHTML = blocTexte;
    }else{
      const div: HTMLDivElement = this.renderer.createElement("div");
      div.innerHTML = blocTexte;
      div.className = "border2";
      div.id = "R"+id;
      this.renderer.appendChild(this.div2.nativeElement, div);
      this.idPresent.push("R"+id);
    }
  }
  deleteBlocTexte(id:string){
    var titre = document.getElementById(id);
    var titre2 = document.getElementById('R'+id); 
    var button = document.getElementById('B1'+id);
    var button2 = document.getElementById('B2'+id);
    this.renderer.removeChild(this.div2.nativeElement,titre2);
    this.renderer.removeChild(this.div.nativeElement,titre);
    this.renderer.removeChild(this.div.nativeElement,button);
    this.renderer.removeChild(this.div.nativeElement,button2);
  }

  addImage(id:string) {
    this.i+=1;
    const input: HTMLTextAreaElement = this.renderer.createElement("input");
    input.id = id+this.i;
    this.idPresent.push(input.id);
    this.renderer.appendChild(this.div.nativeElement, input);
    const button: HTMLButtonElement= this.renderer.createElement("button");
    button.innerHTML = "ajouter";
    button.id="B1"+input.id;
    button.addEventListener('click', this.addImageContent.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button);
    const button2: HTMLButtonElement= this.renderer.createElement("button");
    button2.innerHTML = "supprimer cet élément";
    button2.id="B2"+input.id;
    button2.addEventListener('click', this.deleteImage.bind(this, input.id));
    this.renderer.appendChild(this.div.nativeElement, button2);
  }
  addImageContent(id:string){
    var urlImage = (<HTMLInputElement>document.getElementById(id)).value;
    if(this.idPresent.indexOf("R"+id)>-1){
      document.getElementById('R'+id).innerHTML = urlImage;
    }else{
      const img: HTMLImageElement = this.renderer.createElement("img");
      img.className = "imagebord";
      img.setAttribute("src", urlImage);
      img.id = "R"+id;

      this.renderer.appendChild(this.div2.nativeElement, img);
      this.idPresent.push("R"+id);
    }
  }
  deleteImage(id:string){
    var titre = document.getElementById(id);
    var titre2 = document.getElementById('R'+id); 
    var button = document.getElementById('B1'+id);
    var button2 = document.getElementById('B2'+id);
    this.renderer.removeChild(this.div2.nativeElement,titre2);
    this.renderer.removeChild(this.div.nativeElement,titre);
    this.renderer.removeChild(this.div.nativeElement,button);
    this.renderer.removeChild(this.div.nativeElement,button2);
  }
}
