<div id="codeAGenerer">
<div class="recapSemaine"><div class="fondcode"><div class="titrepresa">RECAP SEMAINE XXX: <br />
    VIES & SANTE MENTALE</div>
    
    <div class="soustitresuj">SEMAINE XXX</div>
    <div style="display:flex;justify-content:center;align-items:stretch"><div class="pseudo" style="width:40%"><num2>VIES</num2></div><div class="pdsm" style="width:40%"><num2>SANTE MENTALE</num2></div></div>
    
    <div class="recap" *ngFor="let survivant of survivants" >
        <div id="{{survivant.pseudo}}"></div>
    </div>
    </div>
    </div>

</div>
    <a href="#" id="generer-html">&#9658; Générer le code</a><br />
  <textarea id="txt-genenere" readonly>
  </textarea>