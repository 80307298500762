import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { SignComponent } from './sign/sign.component';
import { SurvieComponent } from './survie/survie.component';
import { SurvieParPersoComponent } from './survie-par-perso/survie-par-perso.component';
import { PostComponent } from './post/post.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import * as $ from 'jquery';
import { RecapComponent } from './recap/recap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostService } from './services/post.service';
import { SurvieService } from './services/survie.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


const appRoutes: Routes = [
  {path: 'post', component:PostComponent},
  {path: 'recap', component:RecapComponent},
  {path : 'sign', component:SignComponent},
  {path : 'survie', component:SurvieComponent},
  {path : 'survieParPerso', component:SurvieParPersoComponent},
  {path : '', redirectTo : 'post', pathMatch:'full'}, 
  {path : '**', redirectTo : 'post'}
]

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SignComponent,
    SurvieComponent,
    SurvieParPersoComponent,
    PostComponent,
    RecapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes), 
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  providers: [PostService,
  SurvieService,
  {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
