<br />
    

<div class="d-flex">
    <div>
        <button type="button" (click)="addTitre('titre')">Ajouter le titre principal</button>
        <button type="button" (click)="addSousTitre('titre')">Ajouter un sous-titre</button>
        <button type="button" (click)="addblocTexte('bloc')">Ajouter un bloc de texte</button>
        <button type="button" (click)="addImage('image')">Ajouter une image</button>
    <div class="test" #div></div>
    <br /><br /><br /><br />
    <a href="#" id="generer-html-messageOffi">&#9658; Générer le code</a><br />
  <textarea id="txt-genenere" readonly>
  </textarea>

</div>
    <div class="fondcode" id="fondcode" #div2></div>
</div>
    