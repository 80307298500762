 
   <label for="survivant">Sélectionner un survivant :</label>
    <select name="survivant" id="survivant" onchange="cacherbloc(this.value)">
        <option value="selection"></option>
        <option *ngFor="let survivant of survivants" [value]="survivant.pseudo">{{survivant.pseudo}}</option>
    </select>
  
  <div class="jaugesSurvie" *ngFor="let survivant of survivants" >
      <div id="{{survivant.pseudo}}" style="display: none;"></div>
  </div>

  <a href="#" id="generer-html-survie">&#9658; Générer le code</a><br />
  <textarea id="txt-genenere" readonly>
  </textarea>