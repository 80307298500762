import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SurvieService {
  survivants = [
    {
      pseudo:'Aidan',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42652'
    },
    {
      pseudo:'Asher',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42673'
    },
    {
      pseudo:'August',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42663'
    },
    {
      pseudo:'Cecil',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42636'
    },
    {
      pseudo:'Charity',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42671'
    },
    {
      pseudo:'Cobie',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42635'
    },
    {
      pseudo:'Eileen',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42646'
    },
    {
      pseudo:'Elaura',
      url:'https://end-game.forumotion.com/t1199p20-01-trigger-warning #p42877'
    },
    {
      pseudo:'Elvis',
      url:'https://end-game.forumotion.com/t1199p20-01-trigger-warning #p42896'
    },
    {
      pseudo:'Harley',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42713'
    },
    {
      pseudo:'Joy',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42748'
    },
    {
      pseudo:'Jules',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42657'
    },
    {
      pseudo:'Kimi',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42770'
    },
    {
      pseudo:'Lazar',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42644'
    },
    {
      pseudo:'Maxence',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42692'
    },
    {
      pseudo:'Mona',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42655'
    },
    {
      pseudo:'Pollux',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42642'
    },
    {
      pseudo:'Ruben',
      url:'https://end-game.forumotion.com/t1199-01-trigger-warning #p42643'
    },
    {
      pseudo:'Sage',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42870'
    },
    {
      pseudo:'Vega',
      url:'https://end-game.forumotion.com/t1199p10-01-trigger-warning #p42826'
    },
    {
      pseudo:'Wolfram',
      url:'https://end-game.forumotion.com/t1199p20-01-trigger-warning #p42880'
    }
  ];

  constructor(){

  }

  recupererSurvieParSurvivant( pseudo : string, url : string){
    $('#'+pseudo).load(url, function(){
      $('.post').each( function(){
        var sante = $(this).find('.profjauges img:nth-child(3)').attr('title');
        var utilite = $(this).find('.profjauges img:nth-child(4)').attr('title');
        var resistance = $(this).find('.profjauges img:nth-child(5)').attr('title');
        var vigilance = $(this).find('.profjauges img:nth-child(6)').attr('title');
        var innovation = $(this).find('.profjauges img:nth-child(7)').attr('title');
        var efficacite = $(this).find('.profjauges img:nth-child(8)').attr('title');

        var santeTxt, utiliteTxt, resistanceTxt, vigilanceTxt, innovationTxt, efficaciteTxt;
        if(sante=="1/7" || sante=="2/7"){
          santeTxt = '<span style="color:magenta;"><strong>MALUS ('+sante+')</strong> • Toutes les semaines, et ce pour assurer sa bonne santé, votre personnage devra se rendre à l\'infirmerie <u>à trois reprises</u> afin d\'effectuer des examens médicaux de routine auprès de N.O.L.A.N. pour assurer sa bonne santé. En cas d\'échec, votre personnage perdra un point de vie à la fin de la semaine.</span>';
        }else if(sante=="3/7" || sante=="4/7" || sante=="5/7"){
           santeTxt = '<span style="color:white;"><strong>NEUTRE ('+sante+')</strong> • Toutes les semaines, et ce pour assurer sa bonne santé, votre personnage devra se rendre à l\'infirmerie <u>à deux reprises</u> afin d\'effectuer des examens médicaux de routine auprès de N.O.L.A.N. pour assurer sa bonne santé. En cas d\'échec, votre personnage perdra un point de vie à la fin de la semaine.</span>';
        }else{
          santeTxt = '<span style="color:lime;"><strong>BONUS ('+sante+')</strong> • Toutes les semaines, et ce pour assurer sa bonne santé, votre personnage devra se rendre à l\'infirmerie <u>à une reprise</u> afin d\'effectuer des examens médicaux de routine auprès de N.O.L.A.N. pour assurer sa bonne santé. En cas d\'échec, votre personnage perdra un point de vie à la fin de la semaine.</span>';
        }

        if(utilite=="1/7" || utilite=="2/7"){
          utiliteTxt = '<span style="color:magenta;"><strong>MALUS ('+utilite+')</strong> • Votre personnage est perçu comme étant un fardeau pour le reste du groupe. Toutes les semaines, au vote d\'exil, il aura automatiquement une voix contre lui d\'office (+1 voix contre par semaine).</span>';
        }else if(utilite=="3/7" || utilite=="4/7" || utilite=="5/7"){
           utiliteTxt = '<span style="color:white;"><strong>NEUTRE ('+utilite+')</strong> • Votre personnage n\'est perçu comme étant ni essentiel, ni encombrant pour le groupe. Il n\'y a ni bonus, ni malus au vote d\'exil.</span>';
        }else{
          utiliteTxt = '<span style="color:lime;"><strong>BONUS ('+utilite+')</strong> • Votre personnage est perçu comme étant un atout essentiel aux yeux du reste du groupe. Toutes les semaines, au vote d\'exil, une voix contre lui sera automatiquement annulée au vote d\'exil (-1 voix contre par semaine).</span>';
        }

        if(resistance=="1/7" || resistance=="2/7"){
          resistanceTxt = '<span style="color:magenta;"><strong>MALUS ('+resistance+')</strong> • Paliers 1 et 2.</font> Votre personnage est particulièrement susceptible aux entités. En cas de ciblage par l\'un des hôtes, votre personnage a 75% de chances d\'être transformé en hôte.</span>';
        }else if(resistance=="3/7" || resistance=="4/7" || resistance=="5/7"){
           resistanceTxt = '<span style="color:white;"><strong>NEUTRE ('+resistance+')</strong> • Votre personnage n\'est pas particulièrement susceptible ni résistant aux entités. En cas de ciblage par l\'un des hôtes, votre personnage a 50% de chances d\'être transformé en hôte.</span>';
        }else{
          resistanceTxt = '<span style="color:lime;"><strong>BONUS ('+resistance+')</strong> •  Votre personnage est particulièrement résistant aux entités. En cas de ciblage par l\'un des hôtes, votre personnage a 25% de chances d\'être transformé en hôte.</span>';
        }

        if(vigilance=="1/7" || vigilance=="2/7"){
          vigilanceTxt = '<span style="color:magenta;"><strong>MALUS ('+vigilance+')</strong> • Votre personnage n\'est pas très prudent. Toutes les semaines, un indice au hasard sera dévoilé sur la mission de son métier à un autre personnage tiré au sort.</span>';
        }else if(vigilance=="3/7" || vigilance=="4/7" || vigilance=="5/7"){
           vigilanceTxt = '<span style="color:white;"><strong>NEUTRE ('+vigilance+')</strong> • Votre personnage est suffisamment prudent pour ne pas perdre d\'indices, mais pas suffisamment attentif pour en gagner. (Pas de bonus ni de malus)</span>';
        }else{
          vigilanceTxt = '<span style="color:lime;"><strong>BONUS ('+vigilance+')</strong> • Votre personnage est très attentif. Toutes les semaines, il aura le droit de réclamer un indice sur la mission du personnage de son choix.</span>';
        }

        if(innovation=="1/7" || innovation=="2/7"){
          innovationTxt = '<span style="color:magenta;"><strong>MALUS ('+innovation+')</strong> • Votre personnage n\'est pas très imaginatif. Quel que soit le lieu exploré, l\'action #1 lui sera systématiquement barrée.</span>';
        }else if(innovation=="3/7" || innovation=="4/7" || innovation=="5/7"){
           innovationTxt = '<span style="color:white;"><strong>NEUTRE ('+innovation+')</strong> • Votre personnage peut effectuer toutes les actions mises à disposition lors de ses explorations.</span>';
        }else{
          innovationTxt = '<span style="color:lime;"><strong>BONUS ('+innovation+')</strong> • Votre personnage est très imaginatif. Toutes les semaines, il aura le droit d\'inventer deux interactions de son choix lors de ses explorations des lieux.</span>';
        }

        if(efficacite=="1/7" || efficacite=="2/7"){
          efficaciteTxt = '<span style="color:magenta;"><strong>MALUS ('+efficacite+')</strong> • Votre personnage n\'est pas très efficace. Ainsi, toutes les semaines, il aura deux tâches de plus à effectuer que ses camarades.</span>';
        }else if(efficacite=="3/7" || efficacite=="4/7" || efficacite=="5/7"){
           efficaciteTxt = '<span style="color:white;"><strong>NEUTRE ('+efficacite+')</strong> • Votre personnage est plutôt efficace. Toutes les semaines, il aura un nombre de tâches normal à effectuer.</span>';
        }else{
          efficaciteTxt = '<span style="color:lime;"><strong>BONUS ('+efficacite+')</strong> • Votre personnage est très efficace. Toutes les semaines, il aura donc deux tâches de moins à effectuer que ses camarades.</span>';
        }
        $('#'+pseudo).html('<div class="infosSurvie border2"><num2>SANTÉ</num2> <span class="sante">'+santeTxt+'</span><br /><num2>UTILITÉ</num2><span class="utilite">'+utiliteTxt+'</span><br /><num2>RÉSISTANCE</num2><span class="resistance">'+resistanceTxt+'</span><br /><num2>VIGILANCE</num2><span class="vigilance">'+vigilanceTxt+'</span><br /><num2>INNOVATION</num2><span class="innovation">'+innovationTxt+'</span><br /><num2>EFFICACITÉ</num2><span class="efficacite">'+efficaciteTxt+'</span></div></div>');
      });
  });
  }

  recupListe(){
    var bonusSante = "";
    var malusSante = "";
    var bonusUtilite = "";
    var malusUtilite = "";
    var bonusResistance = "";
    var malusResistance = "";
    var bonusVigilance = "";
    var malusVigilance = "";
    var bonusInnovation = "";
    var malusInnovation = "";
    var bonusEfficacite = "";
    var malusEfficacite = "";
    $('#page1').load('https://end-game.forumotion.com/t1199-01-trigger-warning #wrap', function(){
      $('.post').each( function(){

        var pseudo = $(this).find('.postprofile-name strong').text();
        var sante = $(this).find('.profjauges img:nth-child(3)').attr('title');
        var utilite = $(this).find('.profjauges img:nth-child(4)').attr('title');
        var resistance = $(this).find('.profjauges img:nth-child(5)').attr('title');
        var vigilance = $(this).find('.profjauges img:nth-child(6)').attr('title');
        var innovation = $(this).find('.profjauges img:nth-child(7)').attr('title');
        var efficacite = $(this).find('.profjauges img:nth-child(8)').attr('title');
        
        if(pseudo == "Game Master"){
        }
        else{
          if(vigilance=="1/7" || vigilance=="2/7"){
            malusVigilance+='@"'+pseudo+'"';
          }else if(vigilance=="6/7" || vigilance=="7/7"){
            bonusVigilance+='@"'+pseudo+'"';
          }
  
          if(utilite=="1/7" || utilite=="2/7"){
            malusUtilite+='@"'+pseudo+'"';
          }else if(utilite=="6/7" || utilite=="7/7"){
            bonusUtilite+='@"'+pseudo+'"';
          }
    
    if(sante=="1/7" || sante=="2/7"){
      malusSante+='@"'+pseudo+'"';
          }else if(sante=="6/7" || sante=="7/7"){
            bonusSante+='@"'+pseudo+'"';
          }
    if(resistance=="1/7" || resistance=="2/7"){
      malusResistance+='@"'+pseudo+'"';
          }else if(resistance=="6/7" || resistance=="7/7"){
            bonusResistance+='@"'+pseudo+'"';
          }
    if(innovation=="1/7" || innovation=="2/7"){
      malusInnovation+='@"'+pseudo+'"';
          }else if(innovation=="6/7" || innovation=="7/7"){
            bonusInnovation+='@"'+pseudo+'"';
          }
    if(efficacite=="1/7" || efficacite=="2/7"){
      malusEfficacite+='@"'+pseudo+'"';
          }else if(efficacite=="6/7" || efficacite=="7/7"){
            bonusEfficacite+='@"'+pseudo+'"';
          }
          
        }
        $('#page1').html("Bonus Santé : " + bonusSante + "<br /> Malus Santé : " + malusSante + "<br />Bonus Utilité : " + bonusUtilite + "<br /> Malus Utilité : " + malusUtilite + "<br />Bonus Resistance : " + bonusResistance + "<br /> Malus Résistance : " + malusResistance + "<br /> Bonus Vigilance : " + bonusVigilance + "<br /> Malus Vigilance : " + malusVigilance +"<br />Bonus Innovation : " + bonusInnovation + "<br /> Malus Innovation : " + malusInnovation + "<br />Bonus Efficacité : " + bonusEfficacite + "<br /> Malus Efficacité : " + malusEfficacite);
      });
    });
    $('#page2').load('https://end-game.forumotion.com/t1199p10-01-trigger-warning #wrap', function(){
      $('.post').each( function(){

        var pseudo = $(this).find('.postprofile-name strong').text();
        var sante = $(this).find('.profjauges img:nth-child(3)').attr('title');
        var utilite = $(this).find('.profjauges img:nth-child(4)').attr('title');
        var resistance = $(this).find('.profjauges img:nth-child(5)').attr('title');
        var vigilance = $(this).find('.profjauges img:nth-child(6)').attr('title');
        var innovation = $(this).find('.profjauges img:nth-child(7)').attr('title');
        var efficacite = $(this).find('.profjauges img:nth-child(8)').attr('title');
        
        if(pseudo == "Game Master"){
        }
        else{
          if(vigilance=="1/7" || vigilance=="2/7"){
            malusVigilance+='@"'+pseudo+'"';
          }else if(vigilance=="6/7" || vigilance=="7/7"){
            bonusVigilance+='@"'+pseudo+'"';
          }
  
          if(utilite=="1/7" || utilite=="2/7"){
            malusUtilite+='@"'+pseudo+'"';
          }else if(utilite=="6/7" || utilite=="7/7"){
            bonusUtilite+='@"'+pseudo+'"';
          }
    
    if(sante=="1/7" || sante=="2/7"){
      malusSante+='@"'+pseudo+'"';
          }else if(sante=="6/7" || sante=="7/7"){
            bonusSante+='@"'+pseudo+'"';
          }
    if(resistance=="1/7" || resistance=="2/7"){
      malusResistance+='@"'+pseudo+'"';
          }else if(resistance=="6/7" || resistance=="7/7"){
            bonusResistance+='@"'+pseudo+'"';
          }
    if(innovation=="1/7" || innovation=="2/7"){
      malusInnovation+='@"'+pseudo+'"';
          }else if(innovation=="6/7" || innovation=="7/7"){
            bonusInnovation+='@"'+pseudo+'"';
          }
    if(efficacite=="1/7" || efficacite=="2/7"){
      malusEfficacite+='@"'+pseudo+'"';
          }else if(efficacite=="6/7" || efficacite=="7/7"){
            bonusEfficacite+='@"'+pseudo+'"';
          }
          
        }
        $('#page2').html("Bonus Santé : " + bonusSante + "<br /> Malus Santé : " + malusSante + "<br />Bonus Utilité : " + bonusUtilite + "<br /> Malus Utilité : " + malusUtilite + "<br />Bonus Resistance : " + bonusResistance + "<br /> Malus Résistance : " + malusResistance + "<br /> Bonus Vigilance : " + bonusVigilance + "<br /> Malus Vigilance : " + malusVigilance +"<br />Bonus Innovation : " + bonusInnovation + "<br /> Malus Innovation : " + malusInnovation + "<br />Bonus Efficacité : " + bonusEfficacite + "<br /> Malus Efficacité : " + malusEfficacite);
        
      });
    });
    $('#page3').load('https://end-game.forumotion.com/t1199p20-01-trigger-warning #wrap', function(){
      $('.post').each( function(){

        var pseudo = $(this).find('.postprofile-name strong').text();
        var sante = $(this).find('.profjauges img:nth-child(3)').attr('title');
        var utilite = $(this).find('.profjauges img:nth-child(4)').attr('title');
        var resistance = $(this).find('.profjauges img:nth-child(5)').attr('title');
        var vigilance = $(this).find('.profjauges img:nth-child(6)').attr('title');
        var innovation = $(this).find('.profjauges img:nth-child(7)').attr('title');
        var efficacite = $(this).find('.profjauges img:nth-child(8)').attr('title');
        
        if(pseudo == "Game Master"){
        }
        else{
          if(vigilance=="1/7" || vigilance=="2/7"){
            malusVigilance+='@"'+pseudo+'"';
          }else if(vigilance=="6/7" || vigilance=="7/7"){
            bonusVigilance+='@"'+pseudo+'"';
          }
  
          if(utilite=="1/7" || utilite=="2/7"){
            malusUtilite+='@"'+pseudo+'"';
          }else if(utilite=="6/7" || utilite=="7/7"){
            bonusUtilite+='@"'+pseudo+'"';
          }
    
    if(sante=="1/7" || sante=="2/7"){
      malusSante+='@"'+pseudo+'"';
          }else if(sante=="6/7" || sante=="7/7"){
            bonusSante+='@"'+pseudo+'"';
          }
    if(resistance=="1/7" || resistance=="2/7"){
      malusResistance+='@"'+pseudo+'"';
          }else if(resistance=="6/7" || resistance=="7/7"){
            bonusResistance+='@"'+pseudo+'"';
          }
    if(innovation=="1/7" || innovation=="2/7"){
      malusInnovation+='@"'+pseudo+'"';
          }else if(innovation=="6/7" || innovation=="7/7"){
            bonusInnovation+='@"'+pseudo+'"';
          }
    if(efficacite=="1/7" || efficacite=="2/7"){
      malusEfficacite+='@"'+pseudo+'"';
          }else if(efficacite=="6/7" || efficacite=="7/7"){
            bonusEfficacite+='@"'+pseudo+'"';
          }
          
        }
        $('#page3').html("Bonus Santé : " + bonusSante + "<br /> Malus Santé : " + malusSante + "<br />Bonus Utilité : " + bonusUtilite + "<br /> Malus Utilité : " + malusUtilite + "<br />Bonus Resistance : " + bonusResistance + "<br /> Malus Résistance : " + malusResistance + "<br /> Bonus Vigilance : " + bonusVigilance + "<br /> Malus Vigilance : " + malusVigilance +"<br />Bonus Innovation : " + bonusInnovation + "<br /> Malus Innovation : " + malusInnovation + "<br />Bonus Efficacité : " + bonusEfficacite + "<br /> Malus Efficacité : " + malusEfficacite);
        
      });
    });

    setTimeout(() => {   $("#recapBonusMalus").html("Bonus Santé : " + bonusSante + "<br /> Malus Santé : " + malusSante + "<br />Bonus Utilité : " + bonusUtilite + "<br /> Malus Utilité : " + malusUtilite + "<br />Bonus Resistance : " + bonusResistance + "<br /> Malus Résistance : " + malusResistance + "<br /> Bonus Vigilance : " + bonusVigilance + "<br /> Malus Vigilance : " + malusVigilance +"<br />Bonus Innovation : " + bonusInnovation + "<br /> Malus Innovation : " + malusInnovation + "<br />Bonus Efficacité : " + bonusEfficacite + "<br /> Malus Efficacité : " + malusEfficacite);
      ;}, 1000);
  }

  recupRecap( pseudo : string, url : string){
    $('.recap #'+pseudo).load(url, function(){
      $('.post').each( function(){
        var pdv = $(this).find('.profjauges img:first-child').attr('title');
        var pdsm = $(this).find('.profjauges img:nth-child(2)').attr('title');
        var pdvText='', pdsmText='';
        
        switch(pdv){
          case '0/3':
          pdvText = '<div class="pdv" style="width:20%; color:black;">'+pdv+ '</div>';
          break;
          case '1/3':
          pdvText = '<div class="pdv" style="width:20%; color:red;">'+pdv+ '</div>';
          break;
          case '2/3':
          pdvText = '<div class="pdv" style="width:20%; color:magenta;">'+pdv+ '</div>';
          break;  
          default:
          pdvText = '<div class="pdv" style="width:20%; color:white;">'+pdv+ '</div>';
        }
        
        if(pdsm=="0/10"){
          pdsmText = '<div class="pdsm" style="width:20%; color:yellow;">'+pdsm+ '</div>';
        }else if(pdsm=="1/10" || pdsm=="2/10" || pdsm=="3/10"){
          pdsmText = '<div class="pdsm" style="width:20%; color:dodgerblue;">'+pdsm+ '</div>';
        }else if(pdsm=="4/10" || pdsm=="5/10" || pdsm=="6/10"){
          pdsmText = '<div class="pdsm" style="width:20%; color:cyan;">'+pdsm+ '</div>';
        }else{
          pdsmText = '<div class="pdsm" style="width:20%; color:white;">'+pdsm+ '</div>';
        }
        
        $('.recap #'+pseudo).html('<div style="display:flex;justify-content:center;align-items:stretch"><div class="pseudo" style="width:20%">@"'+pseudo+'"</div>'+pdvText+'<div class="pseudo" style="width:20%">@"'+pseudo+'"</div>'+pdsmText+'</div>');
        
      });
    });
  }

  recupSign(pseudo : string, url : string){
    $('.sign #'+pseudo).load(url, function(){

    $('.post').each( function(){
        var sign = $(this).find('.signature_div').html();
        var signS = sign;
        var pdv = $(this).find('.profjauges').html();
        var pdvS = pdv.split('<br>');
        pdvS = pdvS[0].split('<hr>');
        pdvS = pdvS[0].split('  ');
        $('.sign #'+pseudo).html('<div style="display:flex;justify-content:center;align-items:stretch"><div class="pseudo" style="width:10%">'+pseudo+'</div><div class="pdv_pdsm" style="width:20%">'+pdvS[0]+ '<br />' +pdvS[1]+'utilité : <br />'+pdvS[3]+'<br /> vigilance : <br />'+ pdvS[5]+'</div><div class="sign" style="width:70%">'+signS+'</div></div>');
      
});
});

  }

}