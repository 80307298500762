<nav>
            <div class="navbar" routerLinkActive="active">
                <span routerLink="post">Générer un post</span>
            </div>
            <div class="navbar" routerLinkActive="active">
                <span routerLink="sign">Voir les signatures</span>
            </div>
            <div class="navbar" routerLinkActive="active">
                <span routerLink="recap">Voir le recap pdv / pdsm</span>
            </div>
            <div class="navbar" routerLinkActive="active">
                <span routerLink="survie">Voir les Bonus / Malus de Survie</span>
            </div>
            <div class="navbar" routerLinkActive="active">
                <span routerLink="survieParPerso">Jauge de survie par personnage</span>
            </div>
</nav>